import { BOTTOM_SHEET_TYPE } from "types/state";

export const PWA_STATUS = "PWA_STATUS";
export const STORE_UPDATED_TOPLOOKS = "STORE_UPDATED_TOPLOOKS";
export const SELECT_BOTTOM_SHEET_TYPE = "SELECT_BOTTOM_SHEET_TYPE";

export type ConfigActionPayload = {
  type: string;
  payload?: any;
};

export const changePWAStatusAction = (isPWA: boolean): ConfigActionPayload => {
  return {
    type: PWA_STATUS,
    payload: isPWA,
  };
};

export const selectBottomSheetAction = (bottomSheetType: BOTTOM_SHEET_TYPE): ConfigActionPayload => {
  return {
    type: SELECT_BOTTOM_SHEET_TYPE,
    payload: bottomSheetType,
  };
};
