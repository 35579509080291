import { getSurfixByIndex } from "helpers/ui.helper";
import React from "react";

type ImageGridCompProps = {
  item: any;
  index: number;
  isInspiration?: boolean;
};

const ImageGridComp = ({ item, index, isInspiration }: ImageGridCompProps) => {
  const height = window.innerWidth * 1.31007752;
  console.log("item", item);
  return (
    <div className={`w-full h-[${height}px] flex justify relative ${index % 2 === 0 ? "bg-[#f2f2f2]" : "bg-[#c2c2c2]"}`}>
      {item?.imagePath ? <img className="w-full h-full" src={item?.imagePath} alt="" crossOrigin="anonymous" /> : null}
      {isInspiration ? (
        <p className="text-[#fff] font-[Bebas-Neue] text-center font-[16px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{item?.lookName}</p>
      ) : (
        <div className="flex items-end italic w-fit h-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <p className="text-[#fff] font-[Inter-Black] text-center text-[33px] leading-[30px]">{index + 1}</p>
          <p className="text-[#fff] font-[Inter] text-center font-[16px]">{getSurfixByIndex(index)}</p>
        </div>
      )}
      {isInspiration ? <p className="text-[13px] text-white font-[Inter] absolute bottom-[10px] left-1/2 -translate-x-1/2">30</p> : null}
    </div>
  );
};

export default ImageGridComp;
