import { combineReducers } from "redux";
import authorizationReducer from "store/reducers/authorization.reducer";
import loadingReducer from "store/reducers/loading.reducer";
import postReducer from "store/reducers/post.reducer";

import { AuthorizationState, ConfigState, LoadingState, PostState } from "types/state";
import configReducer from "./config.reducer";

const rootReducer = combineReducers({
  authorization: authorizationReducer,
  loading: loadingReducer,
  post: postReducer,
  config: configReducer
});
export interface RootState {
  authorization: AuthorizationState;
  loading: LoadingState;
  post: PostState;
  config: ConfigState;
}

export default rootReducer;
