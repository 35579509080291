import useAuth from "hooks/auth.hook";
import usePost from "hooks/post.hook";
import React, { useState } from "react";
import { HiEllipsisHorizontal } from "react-icons/hi2";

const PostCard = ({ post }: any) => {
  const [height, setHeight] = useState(window.innerWidth * 1.31007752 ? window.innerWidth * 1.31007752 : "auto");
  const { profileHeaderPic } = useAuth();
  const [points, setPoints] = useState(post?.totalPoints ? post.totalPoints : 0);

  return (
    <div className="w-full border-b-[#ddd] border-solid border-b-[5px]">
      <div className="w-full flex justify-between items-center px-[15px] py-[10px]">
        <div className="flex">
          <div className="w-[40px] h-[40px] rounded-full overflow-hidden mr-[8px]">
            <img className="object-fit h-full w-full" loading="lazy" src={post?.authorProfilePicture ? post?.authorProfilePicture : profileHeaderPic} alt="" crossOrigin="anonymous" />
          </div>
          <div className="">
            <p className="font-[Inter-Bold] text-[14px] text-[#555]">{post?.authorUserName}</p>
            <p className="font-[Inter-Light] text-[12px] text-[#555]">{post?.lookName}</p>
          </div>
        </div>
        <div className="relative">
          {/* <div className="text-[13px] font-[Inter]">{points}</div> */}
          <HiEllipsisHorizontal size={20} />
        </div>
      </div>
      <div
        className={`w-full bg-[#eee]`}
        style={{
          height: height,
        }}
        onDoubleClick={() => {}}
      >
        <img className="w-full bg-[#eee] h-full object-cover" src={post?.imagePath} crossOrigin="anonymous" loading="lazy" alt="" />
      </div>
    </div>
  );
};

export default PostCard;
