export const REGISTER_USER = "REGISTER_USER";
export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const GET_USER_POSTS_DATA = "GET_USER_POSTS_DATA";
export const SAVE_MY_POSTS = "SAVE_MY_POSTS";

export type AuthorizationActionPayload = {
  type: string;
  payload?: any;
};

export function registerUserAction(): AuthorizationActionPayload {
  return {
    type: REGISTER_USER,
  };
}

export function getUserPostsAction(): AuthorizationActionPayload {
  return {
    type: GET_USER_POSTS_DATA,
  };
}

export function logoutAction(): AuthorizationActionPayload {
  return {
    type: LOGGED_IN_USER,
    payload: null,
  };
}
