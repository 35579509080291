import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostsAction, uploadPostAction } from "store/actions/posts.actions";
import { getPostsSelector, getVibesSelector } from "store/reducers/post.reducer";

const usePost = () => {
  const dispatch = useDispatch();
  const posts: any = useSelector(getPostsSelector);
  const vibes: any = useSelector(getVibesSelector);
  const [lookName, setlookName] = useState("");

  const getPosts = () => {
    dispatch(getPostsAction());
  };

  const uploadPost = (post: any, onReset: () => void) => {
    let name = lookName || "ootd";

    let formData = new FormData();
    formData.append("image", post);
    dispatch(uploadPostAction(formData, name, onReset));
  };

  return {
    uploadPost,
    lookName,
    setlookName,
    getPosts,
    posts,
    vibes,
  };
};

export default usePost;
