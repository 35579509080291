import { AuthorizationState } from "types/state";
import { AuthorizationActionPayload, LOGGED_IN_USER, SAVE_MY_POSTS } from "store/actions/authorization.actions";
import { RootState } from "store/reducers";
const defaultState: AuthorizationState = {
  userData: null,
  myPosts: null,
};

export default function authorizationReducer(state = defaultState, action: AuthorizationActionPayload) {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        userData: action.payload,
      };
    case SAVE_MY_POSTS:
      return {
        ...state,
        myPosts: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getUserDataSelector = (state: RootState) => {
  return state.authorization.userData;
};

export const getUserPostsDataSelector = (state: RootState) => {
  return state.authorization.myPosts;
};
