import { PostState } from "types/state";
import { RootState } from "store/reducers";
import { PostsActionPayload, SAVE_POSTS, SAVE_VIBES } from "store/actions/posts.actions";

const defaultState: PostState = {
  posts: null,
  vibes: null,
};

export default function postReducer(state = defaultState, action: PostsActionPayload) {
  switch (action.type) {
    case SAVE_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case SAVE_VIBES:
      return {
        ...state,
        vibes: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getPostsSelector = (state: RootState) => {
  return state.post.posts;
};

export const getVibesSelector = (state: RootState) => {
  return state.post.vibes;
};
