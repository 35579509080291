import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "pages/home";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { POSTS_ROUTE, PRIVATE_INVITE_ROUTE, VIBES_ROUTE } from "constants/routes";
import useAuth from "hooks/auth.hook";
import useConfig from "hooks/config.hook";
import TopPosts from "pages/leaderboard";
import Vibes from "pages/vibes";

const MainLayout = lazy(() => import("./layouts/mainLayout"));

function App() {
  const { registerUser, getUserPosts } = useAuth();
  const { isPWA, changePWAStatus } = useConfig();

  useEffect(() => {
    registerUser();
    // if (window.matchMedia("(display-mode: standalone)").matches) {
    //   changePWAStatus(true);
    // }
    // changePWAStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {/* {isPWA ? (
        <> */}
      <Route
        element={
          <Suspense
            fallback={
              <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                <AiOutlineLoading3Quarters />
              </div>
            }
          >
            <MainLayout />
          </Suspense>
        }
      >
        <Route index element={<Home />} />
        <Route path={POSTS_ROUTE} element={<TopPosts />} />
        <Route path={VIBES_ROUTE} element={<Vibes />} />
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<AiOutlineLoading3Quarters />}>
            <Navigate to="/" replace={true} />
          </Suspense>
        }
      />
      {/* </>
      ) : (
        <>
          <Route index element={<Home />} />
          <Route
            path="*"
            element={
              <Suspense fallback={<AiOutlineLoading3Quarters />}>
                <Navigate to={PRIVATE_INVITE_ROUTE} replace={true} />
              </Suspense>
            }
          />
        </>
      )} */}
    </Routes>
  );
}

export default App;
