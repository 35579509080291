import { getTop12ObjectsByHighestScore } from "helpers/array.helper";
import { call, delay, put, select, take } from "redux-saga/effects";
import PostService from "services/post.service";

import { GET_POSTS, SAVE_POSTS, SAVE_VIBES } from "store/actions/posts.actions";

//Workers
export function* workerGetPosts() {
  try {
    const posts: any[] = yield call(() => PostService.getPosts());
    const vibes: any[] = yield call(() => getTop12ObjectsByHighestScore(posts));
    yield put({
      type: SAVE_POSTS,
      payload: posts,
    });
    yield put({
      type: SAVE_VIBES,
      payload: vibes,
    });
  } catch (e: any) {
    console.log("ERROR: ", e);
  }
}

//Watchers
export function* watchGetPosts() {
  while (true) {
    yield take(GET_POSTS);
    yield call(workerGetPosts);
  }
}
