import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserPostsAction, logoutAction, registerUserAction } from "store/actions/authorization.actions";
import { getUserDataSelector, getUserPostsDataSelector } from "store/reducers/authorization.reducer";
import { UserModel } from "types/models";
import HeaderPlaceholder from "assets/images/header.png";
const BaseImgUrl = "https://pub-aab21470c69f4fa388262e5985d14abe.r2.dev/";

const useAuth = () => {
  const dispatch = useDispatch();
  const userData: UserModel | null = useSelector(getUserDataSelector);
  const myPosts: any = useSelector(getUserPostsDataSelector);
  const [profileHeaderPic, setProfileHeaderPic] = useState<string>(userData?.coverImagePath ? `${BaseImgUrl}${userData?.coverImagePath}` : HeaderPlaceholder);

  const logOut = async () => {
    dispatch(logoutAction());
  };

  const registerUser = () => {
    dispatch(registerUserAction());
  };

  const getUserPosts = () => {
    dispatch(getUserPostsAction());
  };

  return {
    logOut,
    registerUser,
    profileHeaderPic,
    getUserPosts,
    myPosts,
  };
};

export default useAuth;
