export const getSurfixByIndex = (index: number) => {
  if (index === 0) {
    return "st";
  } else if (index === 1) {
    return "nd";
  } else if (index === 2) {
    return "rd";
  } else {
    return "th";
  }
};
