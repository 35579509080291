import { useDispatch, useSelector } from "react-redux";
import { changePWAStatusAction, selectBottomSheetAction } from "store/actions/config.actions";
import { getBottomSheetTypeSelector, getIsPWASelector } from "store/reducers/config.reducer";
import { BOTTOM_SHEET_TYPE } from "types/state";

const useConfig = () => {
  const dispatch = useDispatch();
  const isPWA = useSelector(getIsPWASelector);
  const bottomSheetType = useSelector(getBottomSheetTypeSelector);

  const changePWAStatus = (isPWA: boolean) => {
    dispatch(changePWAStatusAction(isPWA));
  };

  const selectBottomSheet = (bottomSheetType: BOTTOM_SHEET_TYPE) => {
    dispatch(selectBottomSheetAction(bottomSheetType));
  };

  return {
    isPWA,
    changePWAStatus,
    selectBottomSheet,
    bottomSheetType,
  };
};

export default useConfig;
