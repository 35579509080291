import TabHeader from "components/headers/tabHeader/tabHeader";
import ImageGridComp from "components/image";
import usePost from "hooks/post.hook";
import { CSSProperties, useEffect, useState } from "react";

const gridStyles: CSSProperties = {
  paddingTop: "52px",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoRows: "minmax(calc(100vw * 0.5625), auto)",
  width: "100vw",
};
function TopPosts() {
  const { posts } = usePost();

  return (
    <main className="h-[100vh] w-[100vw] flex flex-col items-center">
      <TabHeader title="leaderboard" />
      <section style={gridStyles}>
        <ImageGridComp item={{}} index={0} />
        <ImageGridComp item={{}} index={1} />
        <ImageGridComp item={{}} index={2} />
        <ImageGridComp item={{}} index={3} />
        <ImageGridComp item={{}} index={4} />
        <ImageGridComp item={{}} index={5} />
        <ImageGridComp item={{}} index={6} />
        <ImageGridComp item={{}} index={7} />
        <ImageGridComp item={{}} index={8} />
        <ImageGridComp item={{}} index={9} />
        <ImageGridComp item={{}} index={10} />
        <ImageGridComp item={{}} index={11} />
        <ImageGridComp item={{}} index={12} />
        <ImageGridComp item={{}} index={13} />
        <ImageGridComp item={{}} index={14} />
        <ImageGridComp item={{}} index={15} />
        <ImageGridComp item={{}} index={16} />
        <ImageGridComp item={{}} index={17} />
        <ImageGridComp item={{}} index={18} />
        <ImageGridComp item={{}} index={19} />
        <ImageGridComp item={{}} index={20} />
        <ImageGridComp item={{}} index={21} />
        <ImageGridComp item={{}} index={22} />
        <ImageGridComp item={{}} index={23} />
      </section>
    </main>
  );
}

export default TopPosts;
