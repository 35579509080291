import { LoadingState } from "types/state";
import { RootState } from "store/reducers";
import { LoadingActionPayload, LOADING_AUTH_STATUS } from "store/actions/loading.action";

const defaultState: LoadingState = {
  authIsLoading: false,
};

export default function loadingReducer(state = defaultState, action: LoadingActionPayload) {
  switch (action.type) {
    case LOADING_AUTH_STATUS:
      return {
        ...state,
        authIsLoading: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getIsAuthLoadingSelector = (state: RootState) => {
  return state.loading.authIsLoading;
};
