import axios from "axios";

interface PostServiceType {
  getPosts(): Promise<any>;
  uploadUserPost(payload: any, uid: string, userName: string): Promise<any>;
}

const PostService: PostServiceType = {
  getPosts: async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/v2/video/top-videos`);
    return data;
  },
  uploadUserPost: async (payload, uid, userName) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API}/v2/video/upload-user-post-image?uid=${uid}&userName=${userName}&lookName=${payload.lookName}`, payload.formData);
    return data;
  },
};

export default PostService;
