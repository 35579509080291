import { Store } from "redux";
import { Persistor } from "redux-persist";
import { AllEffect, ForkEffect } from "redux-saga/effects";
import { UserModel } from "types/models";

interface RootSagaFunc {
  (): Generator<AllEffect<ForkEffect<void>>, void, unknown>;
}

export interface SagaStore extends Store {
  runSaga(rootSaga: RootSagaFunc): void;
  close(): void;
}

export type StoreConfig = {
  store: SagaStore;
  persistor: Persistor;
};

export type AuthorizationState = {
  userData: null | UserModel;
  myPosts: null;
};

export type PostState = {
  posts: null;
  vibes: null;
};

export type ConfigState = {
  isPWA: false;
  bottomSheetType: BOTTOM_SHEET_TYPE;
};

export type LoadingState = {
  authIsLoading: boolean;
};

export enum BOTTOM_SHEET_TYPE {
  NONE = "NONE",
  CREATE_POST = "CREATE_POST",
  FOLLOWING = "FOLLOWING",
  MANAGE_POINTS = "MANAGE_POINTS",
}
