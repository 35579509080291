import { BOTTOM_SHEET_TYPE, ConfigState } from "types/state";
import { RootState } from "store/reducers";
import { ConfigActionPayload, PWA_STATUS, SELECT_BOTTOM_SHEET_TYPE } from "store/actions/config.actions";

const defaultState: ConfigState = {
  isPWA: false,
  bottomSheetType: BOTTOM_SHEET_TYPE.NONE,
};

export default function configReducer(state = defaultState, action: ConfigActionPayload) {
  switch (action.type) {
    case PWA_STATUS:
      return {
        ...state,
        isPWA: action.payload,
      };
    case SELECT_BOTTOM_SHEET_TYPE:
      return {
        ...state,
        bottomSheetType: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getIsPWASelector = (state: RootState) => {
  return state.config.isPWA;
};

export const getBottomSheetTypeSelector = (state: RootState) => {
  return state.config.bottomSheetType;
};
