import TabHeader from "components/headers/tabHeader/tabHeader";
import PostCard from "components/post/postCard";
import usePost from "hooks/post.hook";
import { useEffect } from "react";

const Home = () => {
  const { getPosts, posts } = usePost();
  useEffect(() => {
    getPosts();
  }, []);

  return (
    <main className="h-[calc(100dvh)] w-[100vw] pb-[60px]">
      <TabHeader title="feed" />
      <div className="h-[calc(100dvh-60px)] element w-[100vw] py-[60px]">
        {posts && posts.length
          ? posts.map((item: any, index: number) => {
              return <PostCard key={index} post={item} />;
            })
          : null}
      </div>
    </main>
  );
};

export default Home;
