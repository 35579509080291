import TabHeader from "components/headers/tabHeader/tabHeader";
import ImageGridComp from "components/image";
import usePost from "hooks/post.hook";
import { CSSProperties } from "react";

const gridStyles: CSSProperties = {
  paddingTop: "52px",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoRows: "minmax(calc(100vw * 0.5625), auto)",
  width: "100vw",
};

function Vibes() {
  const { vibes } = usePost();

  return (
    <main className="h-[100dvh] w-[100vw]">
      <TabHeader title="vibes" />
      <div className="flex flex-col items-center">
        <section style={gridStyles}>
          <ImageGridComp item={vibes ? vibes[0] : {}} index={0} isInspiration />
          <ImageGridComp item={vibes ? vibes[1] : {}} index={1} isInspiration />
          <ImageGridComp item={vibes ? vibes[2] : {}} index={2} isInspiration />
          <ImageGridComp item={vibes ? vibes[3] : {}} index={3} isInspiration />
          <ImageGridComp item={vibes ? vibes[4] : {}} index={4} isInspiration />
          <ImageGridComp item={vibes ? vibes[5] : {}} index={5} isInspiration />
          <ImageGridComp item={vibes ? vibes[6] : {}} index={6} isInspiration />
          <ImageGridComp item={vibes ? vibes[7] : {}} index={7} isInspiration />
          <ImageGridComp item={vibes ? vibes[8] : {}} index={8} isInspiration />
          <ImageGridComp item={vibes ? vibes[9] : {}} index={9} isInspiration />
          <ImageGridComp item={vibes ? vibes[10] : {}} index={10} isInspiration />
          <ImageGridComp item={vibes ? vibes[11] : {}} index={11} isInspiration />
          <ImageGridComp item={vibes ? vibes[12] : {}} index={12} isInspiration />
          <ImageGridComp item={vibes ? vibes[13] : {}} index={13} isInspiration />
          <ImageGridComp item={vibes ? vibes[14] : {}} index={14} isInspiration />
          <ImageGridComp item={vibes ? vibes[15] : {}} index={15} isInspiration />
          <ImageGridComp item={vibes ? vibes[16] : {}} index={16} isInspiration />
          <ImageGridComp item={vibes ? vibes[17] : {}} index={17} isInspiration />
          <ImageGridComp item={vibes ? vibes[18] : {}} index={18} isInspiration />
          <ImageGridComp item={vibes ? vibes[19] : {}} index={19} isInspiration />
          <ImageGridComp item={vibes ? vibes[20] : {}} index={20} isInspiration />
          <ImageGridComp item={vibes ? vibes[21] : {}} index={21} isInspiration />
          <ImageGridComp item={vibes ? vibes[22] : {}} index={22} isInspiration />
          <ImageGridComp item={vibes ? vibes[23] : {}} index={23} isInspiration />
        </section>
      </div>
    </main>
  );
}

export default Vibes;
