import { call, put, select, take } from "redux-saga/effects";
import AuthService from "services/auth.service";
import { GET_USER_POSTS_DATA, LOGGED_IN_USER, REGISTER_USER, SAVE_MY_POSTS } from "store/actions/authorization.actions";

import { LOADING_AUTH_STATUS } from "store/actions/loading.action";
import { getUserDataSelector } from "store/reducers/authorization.reducer";
import { UserModel } from "types/models";

//Workers
export function* workerRegisterUser() {
  yield put({
    type: LOADING_AUTH_STATUS,
    payload: true,
  });
  try {
    const userDataExists: UserModel = yield select(getUserDataSelector);
    if (!userDataExists) {
      const registeredUser: {
        token: string;
        user: UserModel;
      } = yield call(() => AuthService.registerUser());
      const userData = {
        token: registeredUser?.token,
        ...registeredUser?.user,
      };
      yield put({
        type: LOGGED_IN_USER,
        payload: userData,
      });
    }
    yield put({
      type: GET_USER_POSTS_DATA,
    });
  } catch (e: any) {
    console.log("ERROR: ", e);
  }
  yield put({
    type: LOADING_AUTH_STATUS,
    payload: false,
  });
}

export function* workerGetUserPosts() {
  try {
    const userDataExists: UserModel = yield select(getUserDataSelector);
    const userPosts: {
      totalPosts: number;
      postsArray: any[];
    } = yield call(() => AuthService.getMyPosts(userDataExists?._id || ""));
    yield put({
      type: SAVE_MY_POSTS,
      payload: userPosts,
    });
    console.log(":userPosts", userPosts);
  } catch (e: any) {
    console.log("ERROR: ", e);
  }
}

//Watchers
export function* watchRegisterUser() {
  while (true) {
    yield take(REGISTER_USER);
    yield call(workerRegisterUser);
  }
}

export function* watchGetUserPosts() {
  while (true) {
    yield take(GET_USER_POSTS_DATA);
    yield call(workerGetUserPosts);
  }
}
