export const SAVE_POSTS = "SAVE_POSTS";
export const SAVE_VIBES = "SAVE_VIBES";
export const GET_POSTS = "GET_POSTS";
export const UPLOAD_USER_POST = "UPLOAD_USER_POST";

export type PostsActionPayload = {
  type: string;
  payload?: any;
};

export function getPostsAction(): PostsActionPayload {
  return {
    type: GET_POSTS,
  };
}

export function uploadPostAction(formData: any, lookName: string, onReset: () => void): PostsActionPayload {
  return {
    type: UPLOAD_USER_POST,
    payload: {
      formData,
      lookName,
      onReset,
    },
  };
}
