import useConfig from "hooks/config.hook";
import React, { useEffect, useState } from "react";
import { HiSparkles, HiCamera, HiMiniCreditCard } from "react-icons/hi2";
import { BOTTOM_SHEET_TYPE } from "types/state";
import { motion, useViewportScroll } from "framer-motion";
import useAuth from "hooks/auth.hook";

type TitleProps = {
  title: string;
};

const variants = {
  visible: { y: 0 },
  hidden: { y: -60 },
};

const TabHeader = ({ title }: TitleProps) => {
  const { myPosts } = useAuth();
  const { selectBottomSheet } = useConfig();
  const { scrollY }: any = useViewportScroll();

  const [hidden, setHidden] = useState(false);

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
      console.log("visible");
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
      console.log("hidden");
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  return (
    <motion.header className="flex justify-between items-center h-[60px] px-[20px] border-b-[#ddd] border-solid border-b-[5px] fixed top-0 w-full bg-white z-[10]" variants={variants} animate={hidden ? "hidden" : "visible"} transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.2 }}>
      <h2 className="text-[28px] font-[Inter-Black] tracking-[-1px]">{title}</h2>
      <div className="flex items-center">
        <div
          className="flex items-center"
          onClick={() => {
            selectBottomSheet(BOTTOM_SHEET_TYPE.FOLLOWING);
          }}
        >
          <HiSparkles size={24} />
          <span className="mx-2 text-[13px] font-[Inter]">17</span>
        </div>
        <div
          className="flex items-center"
          onClick={() => {
            selectBottomSheet(BOTTOM_SHEET_TYPE.CREATE_POST);
          }}
        >
          <HiCamera size={24} />
          <span className="mx-2 text-[13px] font-[Inter]">{myPosts?.totalPosts ? myPosts?.totalPosts : 0}</span>
        </div>
        <div
          className="flex items-center"
          onClick={() => {
            selectBottomSheet(BOTTOM_SHEET_TYPE.MANAGE_POINTS);
          }}
        >
          <HiMiniCreditCard size={24} />
          <span className="ml-2 text-[13px] font-[Inter]">30</span>
        </div>
      </div>
    </motion.header>
  );
};

export default TabHeader;
