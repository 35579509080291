export const getTop12ObjectsByHighestScore = (array: any) => {
  return new Promise((resolve, reject) => {
    // Validate input
    if (!Array.isArray(array) || array.length === 0) {
      reject("Invalid input. Please provide a non-empty array of objects.");
    }

    // Create a Map to store the highest score for each unique lookName
    const lookNameMap = new Map();

    // Iterate through the array and update the Map with the highest score for each lookName
    array.forEach((obj: any) => {
      const scoresArray = obj.scoresArray || [];
      const highestScore = Math.max(...scoresArray, 0);

      if (!lookNameMap.has(obj.lookName) || highestScore > lookNameMap.get(obj.lookName).score) {
        lookNameMap.set(obj.lookName, { score: highestScore, object: obj });
      }
    });

    // Sort the lookNames based on the highest score in descending order
    const sortedLookNames = Array.from(lookNameMap.entries())
      .sort((a, b) => b[1].score - a[1].score)
      .slice(0, 12);

    // Get the top 12 objects corresponding to the top lookNames
    const top12Objects = sortedLookNames.map((entry) => entry[1].object);

    // Resolve with the top 12 objects
    resolve(top12Objects);
  });
};
