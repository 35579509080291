import { UserModel } from "types/models";
import axios from "axios";

interface AuthServiceType {
  registerUser(): Promise<UserModel>;
  getMyPosts(uid: string): Promise<UserModel>;
}

const AuthService: AuthServiceType = {
  registerUser: async () => {
    const { data } = await axios.post(`${process.env.REACT_APP_API}/v2/user/generate-user`);
    return data;
  },
  getMyPosts: async (uid) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/v2/user/my-posts?uid=${uid}`);
    return data;
  },
};

export default AuthService;
